<template>
  <v-container
    fluid
    class="pa-0 ma-0 hideScroll"
    :style="`display: flex; height: 100vh; flex-direction: ${
      $vuetify.breakpoint.mobile ? 'column' : 'row'
    }`"
  >
    <div
      id="headContainer"
      :style="`display: flex; flex-direction: column; position: relative; flex-grow: ${
        $vuetify.breakpoint.mobile ? '0' : '1'
      }`"
    >
      <presentation-overlay></presentation-overlay>
      <filter-dialog
        @closeDialog="switchItem('filter')"
        :show="activeType === 'filter'"
        v-if="activeType == 'filter'"
      >
      </filter-dialog>
      <v-sheet style="position: relative; width: 100%" :class="sheetHeight">
        <v-responsive style="height: 100%; width: 100%" v-if="!loading">
          <editorPlayer
            v-if="activeType == 'clip' || activeType == 'time'"
            style="margin-inline: auto; margin: auto"
            v-show="starttimeLoaded"
            :key="player_key"
            :options="options"
          ></editorPlayer>

          <div
            v-if="activeType == 'map'"
            id="mapContainer"
            style="
              display: flex;
              height: 100%;
              width: 100%;
              justify-content: center;
              align-items: center;
            "
          >
            <Map
              v-if="activeType == 'map'"
              :maxHeight="windowHeight * 0.8"
              :maxWidth="windowWidth * 0.8"
              :value="points"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              :hideButtons="true"
              :disableClicks="true"
              :url="map.url"
            ></Map>
          </div>

          <div
            v-if="activeType == 'text_file'"
            style="margin: auto; width: 80%"
            :style="`height: ${
              $vuetify.breakpoint.mobile ? '312.875px' : '80%'
            }`"
          >
            <open-text-file
              style="margin-top: 6%"
              :text_file_id="textId"
              presentation="true"
            ></open-text-file>
          </div>

          <Comment></Comment>
        </v-responsive>
        <v-sheet
          v-if="loading"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
          "
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-sheet>
      </v-sheet>
      <v-sheet
        style="z-index: 2; width: 100%"
        :class="$vuetify.breakpoint.mobile ? 'mobile-bar' : ''"
      >
        <editor-bar
          v-if="
            (activeType == 'clip' && !loading) ||
            (activeType == 'time' && !loading)
          "
        :times="preseRawData"
        ></editor-bar>
        <div
          v-show="
            (activeType == 'clip' && loading) ||
            (activeType == 'time' && loading)
          "
          style="height: 100px"
        ></div>
        <bar hideTimes="true" presentation="true" showCommentBtn="true"></bar>
      </v-sheet>
    </div>
    <v-sheet
      :class="$vuetify.breakpoint.mobile ? 'mobile-bar' : ''"
      :style="{ width: barSheetWidth }"
      style="overflow-y: auto"
    >
      <presentation-side-bar :folderId="this.id"></presentation-side-bar>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["id"],
  components: {
    FilterDialog: () => import("../components/presentation/FilterDialog.vue"),
    PresentationOverlay: () => import("../components/presentation/PresentationOverlay.vue"),
    EditorPlayer: () => import("../components/video/ClipEditor/EditorPlayer.vue"),
    Bar: () => import("../components/video/player/Bar.vue"),
    PresentationSideBar: () => import("../components/presentation/PresentationSideBar.vue"),
    Map: () => import("../components/maps/Map.vue"),
    OpenTextFile: () => import("../components/textfile/OpenTextFile.vue"),
    EditorBar: () => import("@/components/video/ClipEditor/EditorBar.vue"),
    Comment: () => import("../components/comment/Comment.vue"),
  },
  created() {
    if (this.$route.query.event_id) {
      this.openPresentation({
        id: this.$route.params.id,
        query: this.$route.query.event_id,
      });
    } else {
      this.openPresentation({ id: this.$route.params.id });
    }

    window.addEventListener("resize", this.updateScale);

    this.updateScale();
  },
  methods: {
    ...mapActions("clip", ["clipById"]),
    ...mapActions("player", ["openClip", "pause", "play"]),
    ...mapActions("noti", ["error"]),
    ...mapActions("presentation", [
      "loadTime",
      "loadClip",
      "loadMap",
      "loadTextFile",
      "handleInstance",
      "openPresentation",
      "switchItem",
    ]),
    ...mapActions("draw", ["updateScale"]),
  },
  computed: {
    ...mapGetters("presentation", [
      "preseRawData",
      "options",
      "loading",
      "player_key",
      "map",
      "points",
      "textId",
      "sideBarHidden",
      "activeItem",
      "activeType",
    ]),
    ...mapGetters("filter", ["filters"]),
    ...mapGetters("draw", ["layers"]),
    ...mapGetters("player", ["currentTime"]),
    windowHeight() {
      return window.innerHeight
    },
    windowWidth() {
      return window.innerWidth
    },
    sideBarIcon() {
      return this.sideBarHidden
        ? "mdi-arrow-collapse-left"
        : "mdi-arrow-collapse-right";
    },
    // sideBar() {
    //   if (this.$vuetify.theme.dark) return "sidebar-dark";
    //   return "sidebar";
    // },
    // formDataForBar() {
    //   let data = this.layers?.map((i) => {
    //     return {
    //       video_time: i.starttime,
    //       name: "",
    //     };
    //   });
    //   return { data };
    // },
    // mapHeight() {
    //   if (!this.$vuetify.breakpoint.mobile) return "545";
    //   return "350";
    // },
    // mapWidth() {
    //   if (!this.$vuetify.breakpoint.mobile) return "545";
    //   return "350";
    // },
    barSheetWidth() {
      if (this.$vuetify.breakpoint.mobile) return "100vw";
      return this.sideBarHidden ? "53px" : "250px";
    },
    sheetHeight() {
      if (this.activeType === "text_file" || this.activeType === "map")
        return this.$vuetify.breakpoint.mobile
          ? "mobile-still"
          : "desktop-still";
      return this.$vuetify.breakpoint.mobile ? "mobile-video" : "desktop-video";
    },
    // columOrRow() {
    //   return this.$vuetify.breakpoint.mobile
    //     ? "flex-direction: column"
    //     : "flex-direction: row";
    // },
    starttimeLoaded() {
      return (
        this.currentTime > this.options?.starttime - this.options?.buffer - 1
      );
    },
  },
};
</script>

<style scoped>
/* .sidebar-dark {
  background-color: #1e1e1e;
} */

.desktop-video {
  height: calc(100vh - 165px);
}
.desktop-still {
  height: calc(100vh);
}

@media (orientation: landscape) {
  .mobile-video {
    height: calc(100vh) !important;
    overflow-y: none;
  }
  .mobile-bar {
    display: none;
  }
}

@media (orientation: portrait) and (min-width: 700px) {
  .mobile-video {
    height: 39vh;
  }
  .mobile-still {
    height: calc(39vh + 100px);
  }
  /* #headContainer {
    margin-top: 10%;
  } */
}
@media (orientation: portrait) and (max-width: 700px) {
  .mobile-video {
    height: 26vh;
  }
  .mobile-still {
    height: calc(26vh + 100px);
  }
  /* #headContainer {
    margin-top: 10%;
  } */
}
</style>
